<template>
  <div class="advert-home position-relative">
    <b-overlay :show="navigationBusy">
      <pagination :meta="navigationsMeta" :implement-search="false" @change-page="onChangePage" @refresh="onRefresh">
        <b-button slot="action" :to="{name: 'advert.store'}" class="btn btn-label-success btn-sm border-0">
          {{ $t("COMMON.CREATE") }}
        </b-button>
      </pagination>
      <b-row>
        <b-col v-for="navigation in navigations" :key="`navigation_${navigation.id}`" md="3" sm="6" class="d-flex">
          <portlet body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm" foot-class="p-3">
            <template slot="title"><span></span></template>
            <template slot="body">
              <div class="item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__head_image">
                      <b-img class="" :src="navigation.preview" fluid-grow rounded />
                    </div>
                  </div>
                  <div class="kt-widget__body d-flex flex-column">
                    <div class="kt-widget__item mt-auto">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">{{ $t('PAGES.ADVERTS.HOME.LABEL.TYPE') }}</span>
                        <span class="kt-widget__data">{{ targets[navigation.target] }}</span>
                      </div>
                      <template v-if="['external_link'].includes(navigation.target)">
                        <div class="kt-widget__contact">
                          <span class="kt-widget__label">{{ $t('PAGES.ADVERTS.HOME.LABEL.TARGET_TITLE.external_link') }}</span>
                          <span class="kt-widget__data">
                            <a :href="navigation.to" target="_blank" class="kt-font-info"><i class="fa fa-link"></i>{{ $t('PAGES.ADVERTS.HOME.LABEL.TARGET_TITLE.link') }}</a>
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:foot>
              <b-btn-group class="w-100">
                <b-button variant="primary" :to="{name: 'advert.edit', params: {id: navigation.id}}" block>{{ $t('COMMON.FORM.BUTTONS.EDIT') }}</b-button>
                <b-dropdown variant="info">
                  <b-dropdown-item @click="onClickDelete(navigation.id)">
                    <i class="fa fa-trash"></i> {{ $t('COMMON.FORM.BUTTONS.DELETE') }}
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-text text-class="d-flex ">
                    <b-checkbox switch v-model="navigation.is_show" @change="onChangeSwitch( navigation.id, 'is_show', $event )"> {{ $t('COMMON.FORM.LABEL.is_show') }} </b-checkbox>
                  </b-dropdown-text>
                </b-dropdown>
              </b-btn-group>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!navigations.length" background="warning" :title="$t('PAGES.ADVERTS.HOME.TIPS.NODATA_TITLE')" :desc="$t('PAGES.ADVERTS.HOME.TIPS.NODATA')" >
        <template slot="action">
          <b-button :to="{name: 'advert.store'}" class="btn-custom btn-bold btn-upper btn-font-sm  btn-warning">{{ $t('PAGES.ADVERTS.HOME.LABEL.BUTTON.STORE') }}</b-button>
        </template>
      </no-result>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NoResult from "@v@/partials/content/NoResult";
  import navigationMixin from "@/mixins/navigation.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import pagination from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import msgBoxConfirmMixin from "@m@/common/msgBoxConfirmMixin";
  import {SET_NAVIGATION_ERROR} from "@/store/modules/navigation.module";

  export default {
    name: "index",
    components: { Portlet, pagination, NoResult },
    mixins: [navigationMixin, formBusyMixin, commonMethodMixin, msgBoxConfirmMixin],
    data(){
      return {
        navigationBusy: false,
        targets: {
          article: this.$t('PAGES.ADVERTS.HOME.LABEL.TARGET.article'),
          mp: this.$t('PAGES.ADVERTS.HOME.LABEL.TARGET.mp'),
          external_link: this.$t('PAGES.ADVERTS.HOME.LABEL.TARGET.external_link'),
        },
        targets_title: {
          article: this.$t('PAGES.ADVERTS.HOME.LABEL.TARGET_TITLE.article'),
        },
        page: 1,
        title: ''
      };
    },
    computed: {},
    created() {
      this.getNavigations(1, '');
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ADVERTS.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: {name: 'advert.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ADVERTS.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE')});
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page; this.title = title;
        this.getNavigations(page, title);
      },
      onRefresh(title){
        this.getNavigations(1, title)
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'navigations',
          id, column, mutationType: SET_NAVIGATION_ERROR,
          begin: ()=>{ this.showBusy('navigationBusy') },
          after: ()=>{ this.hideBusy('navigationBusy') },
        });
      },
      onClickDelete(id){
        this.msgBoxConfirm({
          message: this.$t('PAGES.ADVERTS.HOME.TIPS.DELETE'),
          confirmCallback: confirm=>{
            if ( confirm ){
              this.deleteModel({
                id, mutationType: SET_NAVIGATION_ERROR, resource: 'navigations',
                begin: ()=>{ this.showBusy('navigationBusy')},
                after: ()=>{
                  let { title, page } = this;
                  this.getNavigations(page, title);
                  this.hideBusy('navigationBusy');
                },
              })
            }
          }
        });
      }
    }
  }
</script>

<style scoped>
  .item-container {
    width: 100%;
  }

  .kt-widget__head_image {
    width: 100%;
    max-height: 150px;
    overflow: hidden;
    border-radius: 8px;
  }

  .item-title {
    height: calc(1.25rem + .5rem);
    width: 100%;
  }

  .item-image-wrapper{
    width: 100%;
    max-height: 200px;
    height: 200px;
  }
</style>
