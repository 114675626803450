<template>
  <b-overlay :show="navigationBusy">
    <portlet :title="$t('PAGES.ADVERTS.EDIT.TITLE')" foot-class="d-flex gap-10">
      <template v-slot:body>
        <navigation-form
          action="edit"
          ref="banner"
          :model-id="advertId"
          @loading="onLoading"
          @loaded="onLoaded"
          @updating="onLoading"
          @updated="onUpdated"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          {{ $t('COMMON.FORM.BUTTONS.SUBMIT') }}
        </b-button>
        <b-button variant="danger" @click="onClickReset">
          {{ $t('COMMON.FORM.BUTTONS.CANCEL') }}
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NavigationForm from "@v@/components/forms/NavigationForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import {debugConsole} from "@c@/helpers/helpers";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    components: { Portlet, NavigationForm },
    mixins: [ formBusyMixin, sweetAlertMixin ],
    computed: {
      advertId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ADVERTS.EDIT.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: {name: 'advert.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ADVERTS.EDIT.BREAD_CRUMBS_TITLES.CURRENT_TITLE')});
      if ( this.$refs.hasOwnProperty('banner') ){
        this.form = this.$refs['banner'];
      }
    },
    data(){
      return {
        navigationBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onLoading(){
        this.showBusy('navigationBusy');
        debugConsole({prefix: 'advert', message: 'onLoading'})
      },
      onLoaded({response, state}){
        this.hideBusy('navigationBusy');
        debugConsole({prefix: 'advert', message: `onLoaded: ${state}`});
      },
      onUpdated({response, state}){
        this.hideBusy('navigationBusy');
        debugConsole({prefix: 'advert', message: `onUpdated: ${state}`});
        if ( state === 'success' ){
          this.success({ message: this.$t('COMPONENTS.FORMS.NAVIGATION_FORM.FORM.TIPS.EDIT_SUCCESS'), title: '' });
        }
      },
      onClickReset(){
        this.$router.replace({name: 'advert.home'});
      },
    }
  }
</script>

<style scoped>

</style>
