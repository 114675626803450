<template>
  <b-overlay :show="navigationBusy">
    <portlet :title="$t('PAGES.ADVERTS.STORE.TITLE')" foot-class="d-flex gap-10">
      <template v-slot:body>
        <navigation-form
          ref="banner"
          @storing="onStoring"
          @stored="onStored"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          {{ $t('COMMON.FORM.BUTTONS.SUBMIT') }}
        </b-button>
        <b-button variant="danger">
          {{ $t('COMMON.FORM.BUTTONS.CANCEL') }}
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NavigationForm from "@v@/components/forms/NavigationForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  export default {
    name: "index",
    components: { Portlet, NavigationForm },
    mixins: [ formBusyMixin ],
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ADVERTS.STORE.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: {name: 'advert.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.ADVERTS.STORE.BREAD_CRUMBS_TITLES.CURRENT_TITLE')});
      if ( this.$refs.hasOwnProperty('banner') ){
        this.form = this.$refs['banner'];
      }
    },
    data(){
      return {
        navigationBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onStoring(){
        this.showBusy('navigationBusy');
        debugConsole({prefix: 'advert', message: `onStored`});
      },
      onStored(state){
        this.hideBusy('navigationBusy');
        debugConsole({prefix: 'advert', message: `onStored: ${state}`});
        this.$router.replace({name: 'advert.home'});
      }
    }
  }
</script>

<style scoped>

</style>
